import styled from 'styled-components'

/** AssetWrapper styled component. */
export const AssetWrapper = styled.div<{ inverse?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${(p) => p.theme.spacing.xs}px;
  font-size: ${(p) => p.theme.typography.paragraph[4].size}px;
  font-weight: ${(p) => p.theme.typography.paragraph[4].weight};
  text-align: right;
  color: ${(p) => (p.inverse ? '#FFF' : p.theme.colors.grey.grey10)};
`

export const Total = styled.div<{ pending?: boolean; inverse?: boolean }>`
  margin-right: ${(p) => p.theme.spacing.xxs}px;
  color: ${(p) =>
    p.inverse
      ? '#FFF'
      : p.pending
      ? p.theme.colors.grey.grey6
      : p.theme.colors.grey.grey10};
`

export const Price = styled.div`
  margin-left: ${(p) => p.theme.spacing.xxs}px;
`

export const Change = styled.div<{ changeColor?: string }>`
  color: ${(p) => p.changeColor};
  margin-left: ${(p) => p.theme.spacing.xxs}px;
`

export const Bold = styled.span`
  font-weight: ${(p) => p.theme.typography.heading[1].weight};
`

export const TimeStamp = styled.span`
  text-transform: uppercase;
`
