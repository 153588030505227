import * as S from './styles'
import BigNumber from 'bignumber.js'
import React from 'react'
import { AmountDisplay } from 'ui/Common/components/AmountDisplay'

export interface AssetStatsProps {
  fiatBalance: BigNumber | undefined
  unitPrice: BigNumber | undefined
  change?: number
  isPending?: boolean
  timestamp?: number
  inverse?: boolean
}

/**
 * AssetStats component.
 */
function AssetStats({
  isPending,
  timestamp,
  fiatBalance,
  unitPrice,
  change,
  inverse = false,
}: AssetStatsProps) {
  const showChange = typeof change === 'number' && change !== 0

  const changeParams = showChange
    ? { color: 'green', symbol: '+', change: change! }
    : { color: 'red', symbol: '-', change: Math.abs(change!) }

  const date = timestamp && new Date(timestamp * 1000)
  const formattedDate =
    date &&
    `${date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })}, ${date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    })}`

  return (
    <S.AssetWrapper inverse={inverse}>
      {date && (
        <S.TimeStamp>
          {formattedDate}
          <span style={{ margin: '0 8px' }}>|</span>
        </S.TimeStamp>
      )}

      {fiatBalance && (
        <>
          <S.Total pending={isPending} inverse={inverse}>
            TOTAL&nbsp;
            <S.Bold>
              <AmountDisplay
                amount={fiatBalance}
                type="balance"
                fiat
                showTooltip={false}
              />
            </S.Bold>
          </S.Total>
          |
        </>
      )}
      {unitPrice && (
        <S.Price>
          PRICE&nbsp;
          <S.Bold>
            <AmountDisplay
              amount={unitPrice}
              type="balance"
              fiat
              showTooltip={false}
            />
          </S.Bold>
        </S.Price>
      )}

      {showChange && (
        <S.Change changeColor={changeParams.change >= 0 ? 'green' : 'red'}>
          <S.Bold>
            {changeParams.change >= 0 ? '+' : '-'}
            {Math.abs(changeParams.change * 100).toFixed(2)}%
          </S.Bold>
        </S.Change>
      )}
    </S.AssetWrapper>
  )
}

export default AssetStats
