import React from 'react'

export const SortColumnIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={8}
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
    fill="#6C727F"
    {...props}
  >
    <path d="M8 5.32H0l4 2.667zM8 2.667H0L4 0z" />
  </svg>
)
