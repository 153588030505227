import styled, { css, keyframes } from 'styled-components'
import { LARGE_BREAKPOINT } from 'ui/Common/components/Grid'
import { SortColumnIcon } from 'ui/Icons/components/SortColumnIcon'

// asset heights are used multiple times in this file so we'll extract them here
const ASSET_ITEM_HEIGHT_DEFAULT = 144
const ASSET_ITEM_HEIGHT_SMALL = 122

// asset item's container height (to calculate list's defualt height)
const ASSET_LIST_ITEM_COUNT = 4 // how many items should fit in the list without triggering scrollbar
const ASSET_ITEM_CONTAINER_DEFAULT_HEIGHT = ASSET_ITEM_HEIGHT_DEFAULT + 4 // item height + margins
const ASSET_ITEM_CONTAINER_SMALL_HEIGHT = ASSET_ITEM_HEIGHT_SMALL + 4 // item height + margins

export const AssetList = styled.div`
  overflow-y: auto;

  max-height: ${ASSET_LIST_ITEM_COUNT * ASSET_ITEM_CONTAINER_DEFAULT_HEIGHT}px;

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    max-height: ${ASSET_LIST_ITEM_COUNT * ASSET_ITEM_CONTAINER_SMALL_HEIGHT}px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: 8px;
  }
`

export const Amount = styled.div<{
  hasSpinner?: boolean
  hasGraph?: boolean
  isEmpty?: boolean
}>`
  font-size: ${(p) => p.theme.typography.heading[2].size}px;
  font-weight: ${(p) => p.theme.typography.heading[2].weight};
  line-height: ${(p) => p.theme.typography.heading[2].lineHeight}px;
  color: ${(p) => p.hasSpinner && p.theme.colors.grey.grey6};

  > svg {
    position: absolute;
    right: 24px;
    top: ${(p) => (p.hasGraph ? (p.isEmpty ? '57px' : '39px') : '57px')};
    transition: top ease-out 0.2s, opacity 0.25s ease-in-out;

    opacity: ${(p) => (p.hasSpinner ? 1 : 0)};
    pointer-events: ${(p) => (p.hasSpinner ? 'initial' : 'none')};

    @media (max-width: ${LARGE_BREAKPOINT}px) {
      top: ${(p) => (p.hasGraph ? (p.isEmpty ? '43px' : '27px') : '43px')};
      right: 12px;
    }

    > g {
      stroke: ${(p) => p.theme.colors.grey.grey7};
    }
  }
`

export const Wrapper = styled.div<{ initial?: boolean; isFlashing: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.white.white100};
  color: ${(p) => p.theme.colors.grey.grey10};
  padding: 32px 48px 24px;
  height: ${ASSET_ITEM_HEIGHT_DEFAULT}px;
  margin-top: 4px;
  z-index: 1;

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    padding: 20px 32px 18px 28px;
    height: 122px;
  }

  ${ButtonsWrapper} {
    /* display: ${(p) => (p.initial ? 'flex' : 'none')}; */
  }

  &:hover {
    ${ButtonsWrapper} {
      display: flex;
    }

    ${Amount} {
      & > svg {
        top: 39px;
        transition: top ease-in 0.2s;
        transition-delay: 0.1s;

        @media (max-width: ${LARGE_BREAKPOINT}px) {
          top: 27px;
        }
      }
    }
  }

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    height: ${ASSET_ITEM_HEIGHT_DEFAULT}px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.green.green6};
    top: 0;
    left: 0;
    opacity: 0;
    animation: ${(p) => (p.isFlashing ? Blink : '')} 5.1s;
    z-index: 0;

    @media (max-width: ${LARGE_BREAKPOINT}px) {
      height: ${ASSET_ITEM_HEIGHT_SMALL}px;
    }
  }
`

export const Blink = keyframes`
    0% {
        opacity: 0;
    }
    5% {
      opacity: 1;
    }
    100% {
        opacity: 0;
    }
`
export const ChainSection = styled.div`
  position: absolute;
  top: 18px;
  left: 18px;
  z-index: 1;
`

export const NameSection = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(p) => p.theme.typography.heading[6].size}px;
  font-weight: ${(p) => p.theme.typography.heading[6].weight};
  line-height: ${(p) => p.theme.typography.heading[6].lineHeight}px;
  margin-bottom: 8px;
  z-index: 1;

  > svg {
    margin: 0 20px 0 0;
  }
`

export const BalanceSection = styled.div<{ hasGraph?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: ${(p) => (p.hasGraph ? 'space-between' : 'center')};
  z-index: 1;
  margin-left: -105px;

  .assetstats {
    margin-top: 2px;
  }
`

export const RewardIcon = styled.div``

export const ProgressBar = styled.div<{
  rewardIndex: number
}>`
  display: flex;
  margin: 19px 0 13px;
  position: relative;
  text-transform: initial;

  /* Show rewards icon */
  ${RewardIcon} {
    position: absolute;
    bottom: 0px;
    left: ${(p) => (p.rewardIndex - 1) * 14 - 4 + 'px'};

    /* Rewards icon on smaller screen */
    @media (max-width: ${LARGE_BREAKPOINT}px) {
      left: ${(p) => (p.rewardIndex - 1) * 12 - 4 + 'px'};
    }
  }
`

export const ProgressBarItem = styled.div<{
  active?: boolean
  isBorrow?: boolean
}>`
  width: 12px;
  height: 4px;
  margin-right: 2px;
  border-radius: 1px;
  background-color: ${(p) =>
    p.isBorrow
      ? p.theme.colors.grey.grey5
      : p.active
      ? p.theme.colors.green.green6
      : p.theme.colors.grey.grey4};

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    width: 10px;
  }

  /* On hover progressBarItem takes black background (Lock icon) */
  /* &:hover {
    background-color: #000;
  } */
`

export const Available = styled.div`
  color: #000;
  font-size: ${(p) => p.theme.typography.paragraph[4].size}px;
  font-weight: ${(p) => p.theme.typography.paragraph[4].weight};
  line-height: ${(p) => p.theme.typography.paragraph[4].lineHeight}px;
  text-align: right;
  margin-left: ${(p) => p.theme.spacing.xs}px;

  > span {
    color: ${(p) => p.theme.colors.green.green6};
    font-weight: ${(p) => p.theme.typography.heading[4].weight};
    margin-left: ${(p) => p.theme.spacing.xxs}px;
  }
`

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 35px;
`

export const VestingWrapper = styled.div<{
  isFlashing: boolean
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.white.white100};
  color: ${(p) => p.theme.colors.grey.grey10};
  padding: 32px 48px 24px;
  height: ${ASSET_ITEM_HEIGHT_DEFAULT}px;
  width: 100%;
  margin-top: 4px;

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    padding: 20px 32px 18px 28px;
    height: ${ASSET_ITEM_HEIGHT_SMALL}px;
  }

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    height: ${ASSET_ITEM_HEIGHT_DEFAULT}px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.green.green6};
    top: 0;
    left: 0;
    opacity: 0;
    animation: ${(p) => (p.isFlashing ? Blink : '')} 5.1s;
    z-index: 0;

    @media (max-width: ${LARGE_BREAKPOINT}px) {
      height: ${ASSET_ITEM_HEIGHT_SMALL}px;
    }
  }
`

export const VestingControlsWrapper = styled.div<{ withdrawing?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 11px;

  > button {
    color: ${(p) => p.theme.colors.white.white100};
    background-color: ${(p) =>
      p.withdrawing ? '#d2f3b8' : p.theme.colors.green.green6};
    border: none;

    &:hover {
      background-color: ${(p) => (p.withdrawing ? '#d2f3b8' : '#1b7a00')};
    }

    &:active {
      background-color: ${(p) => p.theme.colors.green.green6};
    }

    &:disabled {
      background-color: #d2f3b8;
      &:hover {
        background-color: #d2f3b8;
        color: ${(p) => p.theme.colors.white.white100};
      }
    }
  }
`

type SummaryWrapperProps = {
  inverse: boolean
}

export const SummaryWrapper = styled.div<SummaryWrapperProps>`
  height: ${ASSET_ITEM_HEIGHT_DEFAULT}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.inverse ? '#000' : '#FFF')};
  color: ${(props) => (props.inverse ? '#FFF' : '#000')};
  padding: 0 48px;
  border-radius: 8px;

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    height: ${ASSET_ITEM_HEIGHT_SMALL}px;
    padding: 0 36px 0 26px;
  }
`

export const VestingStats = styled.div<{ withdrawing?: boolean }>`
  display: flex;
  justify-content: flex-end;
  color: #000;
  font-size: ${(p) => p.theme.typography.paragraph[4].size}px;
  font-weight: ${(p) => p.theme.typography.paragraph[4].weight};
  line-height: ${(p) => p.theme.typography.paragraph[4].lineHeight}px;
  text-align: right;

  > svg {
    position: absolute;
    right: 20px;
    top: 64px;
    transition: opacity 0.25s ease-in-out;

    opacity: ${(p) => (p.withdrawing ? 1 : 0)};
    pointer-events: ${(p) => (p.withdrawing ? 'initial' : 'none')};

    @media (max-width: ${LARGE_BREAKPOINT}px) {
      right: 10px;
      top: 50px;
    }

    > g {
      stroke: ${(p) => p.theme.colors.grey.grey7};
    }
  }
`

export const Withdrawing = styled.div`
  display: flex;
  color: ${(p) => p.theme.colors.additional.yellow};
  text-align: right;
  margin-right: ${(p) => p.theme.spacing.xs}px;

  > span {
    margin-left: ${(p) => p.theme.spacing.xxs}px;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 48px;

  height: 40px;
  background-color: ${(p) => p.theme.colors.grey.grey4};
  color: ${(p) => p.theme.colors.grey.grey6};

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    padding: 0 32px 0 28px;
  }
`

export const HeaderItem = styled.div`
  font-size: 10px;
  line-height: 16px;
  font-weight: ${(p) => p.theme.typography.fontWeight.Bold};
  text-transform: uppercase;
`

const tableItem = css`
  display: flex;
  align-items: center;
`

export const ChainsWrapper = styled.div`
  ${tableItem};
  flex: 0 0 32px;
  margin-right: 20px;
`

export const AssetsWrapper = styled.div`
  ${tableItem};
  flex: 0 0 46px;
`

export const BalanceWrapper = styled.div`
  ${tableItem};
  justify-content: end;
  flex: 0 0 104px;
`

export const SortIcon = styled(SortColumnIcon)<{
  $highlightUp?: boolean
  $highlightDown?: boolean
}>`
  margin-left: 4px;

  #part-up {
    fill: ${(p) => (p.$highlightUp ? p.theme.colors.grey.grey10 : undefined)};
  }

  #part-down {
    fill: ${(p) => (p.$highlightDown ? p.theme.colors.grey.grey10 : undefined)};
  }
`

const AnimationWrapper = styled.div`
  &,
  & > * {
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
  }

  ${Wrapper}:hover & > * {
    transition-delay: 0.1s;
  }
`

/** Style for switching visible items */
export const ItemSwitcher = styled(AnimationWrapper)<{
  /**
   * Does switcher have 1 or two items? If it has 1 item then it will hide
   * entire container since there is nothing to switch.
   *
   */
  singleItem?: boolean
}>`
  overflow: hidden;

  /* container tranformations */
  height: ${(props) => `${props.singleItem ? 0 : '100%'}`};
  opacity: ${(props) => (props.singleItem ? 0 : 1)};
  transform: ${(props) => (props.singleItem ? 'translateY(100%)' : 'none')};

  /* container margins */
  margin-top: 8px;
  @media (max-width: ${LARGE_BREAKPOINT}px) {
    margin-top: 4px;
  }

  /* intialize items */
  /* make sure && remains cause it forces styled to generate specific class for each item */
  && > * {
    height: 100%;
    flex: 1 0 100%;
    align-items: center;
    justify-content: end;
    transform: ${(props) => (props.singleItem ? 'none' : 'translateY(0)')};
  }

  /* do the switching when parent wrapper is in hover */
  /* make sure && remains cause it forces styled to generate specific class for each item */
  ${Wrapper}:hover && {
    /* items transformations */
    > * {
      transform: ${(props) =>
        props.singleItem ? 'none' : 'translateY(-100%)'};
    }

    /* container tranformations */
    height: 100%;
    opacity: ${(props) => (props.singleItem ? 1 : 1)};
    transform: ${(props) => (props.singleItem ? 'translateY(0)' : 'none')};
  }
`
export const ActionMenuButton = styled.div`
  display: flex;
  padding: 4px;
  cursor: pointer;

  svg {
    fill: #6c727f;
  }
`
